<template>
    <el-dialog :visible="visible" :title="dialogTitle + '变更'" @close="handleCancel" :close-on-click-modal="false"
        width="500px">
        <el-form :model="form" :inline="false" label-position="right" label-width="150px">
            <el-form-item v-for="key in form.keys" :key="key.label" :label="key.label + '：'">
                {{ key.value }}
            </el-form-item>
            <el-form-item :label="form.label + '：'">
                <el-input ref="dialogEditInput" v-model="form.value" v-format.float></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" @click="handleSave">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import { intFormat, floatFormat } from "@/utils/validate";

export default {
    name: "EditNum",
    data() {
        return {
            visible: false,
            dialogTitle: "",
            isAll: false,
            form: {
                title: "",
                keys: [],
                label: "",
                oldValue: "",
                value: "",
                isSubNum: false
            }
        };
    },
    methods: {
        init(data) {
            this.form = data;
            this.form.value = Math.abs(this.form.value);
            this.dialogTitle = this.form.title;
            if (this.form.title) {
                this.dialogTitle = this.form.title;
            }
            else {
                this.dialogTitle = this.form.label;
            }
            this.visible = true;
            this.$nextTick(_ => {
                this.$refs.dialogEditInput.$refs.input.focus();
            });
        },
        async handleSave() {
            if (this.form.value == "") {
                this.$message({
                    message: this.form.label + "不能为空",
                    type: 'warning'
                });
                this.$nextTick(_ => {
                    this.$refs.dialogEditInput.$refs.input.focus();
                });
                return;
            }
            var value = Number(this.form.value);
            if (value < 0) {
                this.$message({
                    message: this.form.label + "不能小于0",
                    type: 'warning'
                });
                this.$nextTick(_ => {
                    this.$refs.dialogEditInput.$refs.input.focus();
                });
                return;
            }
            if (this.form.isSubNum) {
                value = -value;
            }
            this.form.value = intFormat(value);
            this.$emit(this.form.callbackMethod ?? 'handleSave', this.form);
            this.handleCancel();
        },
        handleCancel() {
            this.form = {
                title: "",
                keys: [],
                label: "",
                value: ""
            };
            this.visible = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.ProPrice {
    ::v-deep {
        .el-form-item__label {
            color: #F56C6C;
        }
    }
}
</style>
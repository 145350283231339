<template>
    <el-dialog v-dialog-drag title="还款" width="400px" :visible="visible" :close-on-click-modal="false"
        @close="visible = false">
        <el-form label-width="100px" :model="form">
            <el-form-item label="客户：">
                {{ form.PurchaserNick ? `（${form.PurchaserNick}）` : '' }}{{ form.PurchaserName }}
            </el-form-item>
            <el-form-item label="累计欠款：">
                ￥<span class="money">{{ form.DebtFee }}</span>
            </el-form-item>
            <el-form-item label="还款：">
                ￥<el-input v-model="form.ReceivePayment" v-format.float />
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="warning" @click="repay('cash')">现金</el-button>
            <el-button type="success" @click="repay('wechat')">微信</el-button>
            <el-button type="primary" @click="repay('alipay')">支付宝</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: "cashierRepay",
    data() {
        return {
            visible: false,
            form: {
                PurchaserID: '',
                PurchaserName: '',
                PurchaserNick: '',
                IsRepayTrade: false,
                TradeIds: '',
                RefundIds: '',
                DebtFee: '',
                ReceivePayment: '',
                PayType: ''
            }
        };
    },
    methods: {
        show(data) {
            this.form = data;
            this.visible = true;
        },
        async repay(payType, repayParam = null) {
            if (!repayParam) {
                repayParam = this.form;
            }
            if (repayParam.ReceivePayment == '') {
                this.$baseMessage('请输入还款金额！', 'error');
                return;
            }
            let debtFee = repayParam.DebtFee;
            let receivePayment = parseFloat(repayParam.ReceivePayment);

            if (debtFee > 0 && receivePayment <= 0) {
                this.$baseMessage('还款金额必须大于0！', 'error');
                return;
            }
            if (debtFee < 0 && receivePayment >= 0) {
                this.$baseMessage('还款金额不能超过欠款！', 'error');
                return;
            }
            if (receivePayment != 0) {
                if (debtFee < 0 == receivePayment < 0 && Math.abs(debtFee) < Math.abs(receivePayment)) {
                    this.$baseMessage('还款金额不能超过欠款！', 'error');
                    return;
                }
                repayParam.payType = payType;
                const { data } = await submit('/api/stallCashier/repay', repayParam);
                if (data.ErrorMsg) {
                    this.$baseMessage(data.ErrorMsg, 'error');
                    return;
                }
                else {
                    this.$baseMessage('还款成功！', 'success');
                }
            }
            this.visible = false;
            this.$emit('refresh');
        }
    },
};
</script>